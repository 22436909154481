const isUrl = function (str) {
    return /^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/.test(str);
}
const getDate = function (date) {
    let arr = date.split(/[-:\s]/);
    if(arr.length < 6) {
        arr[5] = 0;
    }
    return new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
}

const getSuffix = function (filename) {
    let pos = filename.lastIndexOf('.');
    let suffix = '';
    if (pos != -1) {
        suffix = filename.substring(pos);
    }
    return suffix;
};
const isArray = function (arr) {
    return Object.prototype.toString.call(arr) === '[object Array]'
}
const isEmpty = function (str) {
    return str === undefined || str === null || str === '' || str === false;
}
const randomString = function (len) {
    len = len || 32;
    let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let maxPos = chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}
const downLoadFile = function (url, fileName) {
    let eleLink = document.createElement('a');
    eleLink.download = fileName;
    eleLink.style.display = 'none';
    eleLink.target = "_blank";
    eleLink.href = url;
    document.body.appendChild(eleLink);
    eleLink.click();
    document.body.removeChild(eleLink);
}

const clone = function (obj) {
    let o;
    switch (typeof obj) {
        case 'undefined':
            break;
        case 'string'   :
            o = obj + '';
            break;
        case 'number'   :
            o = obj - 0;
            break;
        case 'boolean'  :
            o = obj;
            break;
        case 'object'   :
            if (obj === null) {
                o = null;
            } else {
                if (obj instanceof Array) {
                    o = [];
                    for (let i = 0, len = obj.length; i < len; i++) {
                        o.push(clone(obj[i]));
                    }
                } else if(obj instanceof Date) {
                    o = new Date(obj);
                } else {
                    o = {};
                    for (let k in obj) {
                        o[k] = clone(obj[k]);
                    }
                }
            }
            break;
        default:
            o = obj;
            break;
    }
    return o;
}

const getDistance = function (config) {
    if(!config) return null;
    let {top, right, bottom, left} = config;
    top = top || 0;
    right = right || 0;
    bottom = bottom || 0;
    left = left || 0;
    return `${top}px ${right}px ${bottom}px ${left}px`
}
const formatBooleanText = function (row, column, cellValue) {
    return cellValue == 1 ? '是' : '否';
}

const hotelFormatter = function (row, column, cellValue) {
    let res = '';
    if(cellValue) {
        try {
            res = JSON.parse(cellValue).address;
        } catch (e) {
            console.log(e)
        }
    }
    return res;
}

const getVolume = function(item) {
    if(item.length && item.width && item.height) {
        let vol = item.length / 1000 * item.width / 1000 * item.height / 1000;
        return Math.round(vol * 10000) / 10000;
    } else {
        return '';
    }
}

const filterOption = function(input, option) {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

const getUrlFileName = function (url) {
    let index = url.lastIndexOf('/');
    if(index >= 0) {
        return url.substr(index + 1);
    } else {
        let str = randomString(6);
        index = url.lastIndexOf('.');
        if(index >= 0) {
            return str + url.substr(index);
        } else {
            return str;
        }
    }
}

const goToDetailRoute = function (vm, options) {
    vm.$store.commit("setDetail", {type: options.type, obj: options.item})
    vm.$router.push({
        name: 'EntityDetail',
        params: {type: options.type, id: options.item.id},
        query: options.edit ? {edit: '1'} : {}
    })
}
const typeSelect=function(key){
   if(key==1){
       return '艺术创作'
   }else if(key==2){
       return '生命科学'
   }else if(key==3){
       return '家国天下'
   }else if(key==4){
       return '社会科学'
   }else if(key==5){
       return '科技创新'
   }else if(key==6){
       return '人文历史'
   }else if(key==7){
       return '人与自然'
   }else if(key==8){
       return '地球家园'
   }
}
export { isUrl, getDate, getSuffix, randomString, downLoadFile, clone, getDistance, isEmpty, isArray, formatBooleanText, hotelFormatter, getVolume, filterOption, getUrlFileName, goToDetailRoute,typeSelect };
