import config from './config';
const TOKEN_KEY = 'token';
const UID_KEY = 'uid';
const INFO_KEY= 'info';

const getToken = function() {
  //从配置文件中读取token 若没写入 则从storage中取并写入配置
  let token = config.token;
  if(!token) {
    console.log('Read token from storage.');
    token = getStorage(TOKEN_KEY);
    config.token = token;
  }
  //判断token是否过期 过期返回null
  if(token && Date.now() - token.timestamp > 12 * 60 * 60 * 1000 - 60000) {
    token = null;
  }
  return token;
};

const getStorageInfo = function() {
    let info = config.info;
    if(!info) {
      console.log('Read token from storage.');
      info = getStorage(INFO_KEY);
      config.info = info;
    }
    return info;
}

const setToken = function(res) {
  let temp = {
    timestamp: Date.now(),
    token: res.token
  };
  config.token = temp;
  config.info = res.admin;
  config.uid = res.uid;
  let value = JSON.stringify(temp);
  localStorage.setItem(TOKEN_KEY, value);
  localStorage.setItem(UID_KEY, res.uid);
};

const getStorage = function(key) {
  let result = null;
  let storage = localStorage.getItem(key);
  try {
    storage = JSON.parse(storage);
    result = storage;
  } catch (e) {
    console.log(`Storage for ${key} parse failed.`);
  }
  return result;
};

const cleanToken = function() {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(UID_KEY);
  config.token = null;
  config.uid = null;
};

export { getToken, getStorage, setToken, cleanToken, getStorageInfo }
