const state = {
    title: null,
    collapsed: false,
    detail: null, // 实体详情对象 {type: String, obj: entity}
    form: null, // 添加页默认的关联实体对象 {{key | 字段名 : entity | 实体对象}}
    routes: [], // 顶部面包屑路由数组
};

// getters
const getters = {
    title: state => state.title,
    collapsed: state => state.collapsed,
    detail: state => state.detail,
    form: state => state.form,
    routes: state => state.routes,
};

// mutations
const mutations = {
    setTitle(state, title) {
        state.title = title;
    },
    changeCollapsed(state, collapsed) {
        state.collapsed = collapsed;
    },
    updateRoutes(state, value) {
        state.routes = value;
    },
    setDetail(state, value) {
        state.detail = value;
    },
    setForm(state, value) {
        state.form = value;
    },
};

const actions = {
};

export default {
    state,
    getters,
    mutations,
    actions
}
