import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from "../common/js/storage";
import store from "../store/index";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// import RouteView from "../layouts/RouteView";

Vue.use(VueRouter)

const routes = [
  {
    // path: '/:type',
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "view" */ '../views/Home.vue'),
    redirect: {name: 'List', params: {type: 'article'}},
    children: [
      {
        path: '/identity',
        name: 'Identity',
        component: () => import(/* webpackChunkName: "la" */ '../views/identity/IdentityList.vue'),
        meta: {
          title: '身份管理',
          vs: true
        }
      },
      {
        path: '/entity/:type',
        component: () => import(/* webpackChunkName: "l" */ '../views/Entity.vue'),
        children: [
          {
            path: '',
            name: 'List',
            component: () => import(/* webpackChunkName: "l" */ '../views/EntityList.vue'),
            meta: {
              keepAlive: true
            },
          },
          {
            path: 'add',
            name: 'AddEntity',
            component: () => import(/* webpackChunkName: "a" */ '../views/EntityDetail.vue'),
            meta: {
              keepAlive: false
            },
          },
          {
            path: 'detail/:id',
            name: 'EntityDetail',
            component: () => import(/* webpackChunkName: "d" */ '../views/EntityDetail.vue'),
            meta: {
              keepAlive: false
            },
          }
        ]
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      open: true,
      pt: '登录'
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "404" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start();
  let token = getToken();
  token && store.commit("setLogin", true);
  if (!token && !to.meta.open) {
    next({ name: 'Login' });
  } else {
    let meta = to.meta;
    //页面标题
    let title = meta.title;
    if (title) {
      document.title = title;
    }
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router
