const state = {
    login: false
};

// getters
const getters = {
    login: state => state.login,
};

// mutations
const mutations = {
    setLogin(state, login) {
        state.login = login;
    },
};

const actions = {

};

export default {
    state,
    getters,
    mutations,
    actions
}
