<template>
    <div class="form-upload">
        <a-upload
            accept="image/*"
            :list-type="listType"
            :file-list="fileList"
            :remove="removeFile"
            :before-upload="checkFileSize"
            :custom-request="uploadFile"
            v-if="mediaType == 'picture'">
            <div v-if="limit == -1 || fileList.length < limit">
                <slot name="button" v-if="$slots.button"></slot>
                <template v-else>
                    <a-icon type="plus" />
                    <div class="ant-upload-text">点击上传</div>
                </template>
            </div>
        </a-upload>
        <div class="media-upload" v-else-if="mediaType == 'audio'">
            <input class="file-input" type="file" accept="audio/*" style="display:none;" @change="fileSelect">
            <a-button @click="uploadClick">
                <a-icon type="upload" />
                <span>点击上传</span>
            </a-button>
            <div class="media-list" v-show="fileList && fileList.length > 0">
                <div class="media-item" :class="{error: item.status == 'error'}" v-for="(item, i) in fileList" :key="i">
                    <template v-if="item.status == 'uploading'">
                        <a-progress type="circle" :show-info="false" :stroke-width="4" :width="32" class="media-progress" :percent="item.percent" />
                        <div class="media-title">正在上传</div>
                    </template>
                    <template v-else-if="item.status == 'error'">
                        <a-icon type="exclamation-circle" :style="{color: '#f5222d', fontSize: '32px'}" />
                        <div class="media-title">上传失败</div>
                        <a-icon type="delete" style="cursor: pointer" @click="removeSelectFile(i)"/>
                    </template>
                    <template v-else-if="item.status == 'done'">
                        <audio :src="item.url" controls class="audio-preview" @loadedmetadata="$emit('duration', $event.target.duration)"></audio>
                        <a-icon type="delete" style="cursor: pointer" @click="removeSelectFile(i)"/>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {uploadImg, beforeUpload} from "../common/js/upload";
    import {directUploadTencentCloud} from "../common/js/tencent_cloud";
    import {getUrlFileName} from "../common/js/tool";

    export default {
        name: "form-upload",
        model: {
            event: 'change'
        },
        props: {
            listType: {
                type: String,
                default: 'picture-card'
            },
            // -1无限制
            limit: {
                type: Number,
                default: 1
            },
            //图片上传大小限制
            size: {
                type: Number,
                default: 1024 * 1
            },
            //支持string和array 及用多个链接传入string 可以以,隔开
            value: String,
            mediaType: {
                type: String,
                default: 'picture'
            }
        },
        data() {
            return {
                fileList: []
            }
        },
        watch: {
            value() {
                this.setFileList();
            }
        },
        created() {
            this.setFileList();
        },
        methods: {
            uploadClick() {
                if(this.fileList.length >= this.limit) {
                    return;
                }
                let input = this.$el.querySelector(".file-input");
                input.click();
            },
            fileSelect(e) {
                let file = e.target.files[0];
                let item = {
                    name: file.name,
                    status: 'uploading',
                    url: null,
                    percent: 0
                }
                this.fileList.push(item)
                directUploadTencentCloud(file, p => {
                    this.$set(item, 'percent', Math.round(p.percent * 100));
                }).then(data => {
                    let path = "https://" + data.Location;
                    this.$set(item, 'status' , 'done');
                    this.$set(item, 'url' , path);
                    this.dispatchInput();
                }).catch(() => {
                    this.$set(item, 'status', 'error')
                })
            },
            removeSelectFile(index) {
                this.fileList.splice(index, 1);
                this.dispatchInput();
            },
            checkFileSize(file) {
                return beforeUpload(file, this.size);
            },
            removeFile(file) {
                let list = this.fileList;
                let index = list.findIndex(item => item.uid == file.uid)
                if(index >= 0) {
                    list.splice(index, 1);
                }
                this.dispatchInput();
                return true;
            },
            uploadFile(arg) {
                let file = arg.file;
                let list = this.fileList;
                let item = {
                    uid:  -1 * list.length - 1 + '',
                    name: file.name,
                    status: 'uploading',
                    url: null,
                }
                list.push(item);
                uploadImg({file}).then(url => {
                    this.$set(item, 'status' , 'done');
                    this.$set(item, 'url' , url);
                    this.dispatchInput()
                }).catch(() => {
                    this.$set(item, 'status' , 'error');
                })
            },
            dispatchInput() {
                let list = this.fileList;
                let temp = [];
                list.forEach(item => {
                    if(item.url) {
                        temp.push(item.url);
                    }
                });
                this.$emit('change', temp.length > 0 ? temp.join(',') : null);
            },
            setFileList() {
                let value = this.value;
                if(value) {
                    value = value.split(',');
                    let list = [], len = value.length;
                    len = len > this.limit && this.limit > 0 ? this.limit : len;
                    for(let i = 0; i < len; i ++) {
                        let item = value[i];
                        list.push({
                            uid:  -1 * i - 1,
                            name: getUrlFileName(item),
                            status: 'done',
                            url: item,
                        })
                    }
                    this.fileList = list;
                } else {
                    this.fileList = [];
                }
            }
        }
    }
</script>

<style lang="less">
    .media-list {
        display: flex;
        margin-top: @padding-sm;
    }
    .media-item {
        display: flex;
        align-items: center;
        padding: @padding-xs;
        min-width: 240px;
        border: var(--border);
        border-radius: @border-radius-base;
        &:not(:first-of-type) {
            margin-top: @padding-xs;
        }
        &.error {
            border-color: @error-color;
            color: @error-color;
        }
    }
    .media-title {
        flex: 1;
        margin-left: @padding-sm;
        line-height: 1;
    }
    .audio-preview {
        margin-right: @padding-sm;
        height: 32px;
    }
</style>
