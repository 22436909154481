<template>
    <div class="data-list flex-box vertical">
        <div class="data-title">
            <div class="title-left flex-box">
                <slot name="title" v-bind:total="pagination.total"></slot>
            </div>
            <a-form-model class="query-form" layout="inline" v-if="!hideQuery">
                <slot name="query" v-bind:form="form"></slot>
            </a-form-model>
            <a-space class="title-btn">
                <a-button @click="getList" v-if="!hideQuery">查询</a-button>
                <a-button type="primary" @click="addItem" v-if="addBtn"><a-icon type="plus-circle"/>{{addBtn}}</a-button>
                <slot name="button"></slot>
            </a-space>
        </div>
        <div class="data-area">
            <slot name="table" v-bind:list="list" v-if="$scopedSlots.table"></slot>
            <a-table v-else
                ref="dataTable"
                :columns="renderColumns"
                :row-key="record => record[rowKey]"
                :data-source="url ? list : data"
                :loading="loading"
                :row-selection="rowSelection"
                :pagination="pagination"
                :scroll="scroll"
                :custom-row="customRow"
                @change="handleTableChange">
                <template v-slot:action="text, record, index">
                    <a class="txt-btn" @mousedown.stop @mouseup.stop @click.stop="editRow(record, index)" v-if="!hideEdit">编辑</a>
                    <a-divider type="vertical" v-if="!hideDelete && !hideEdit"></a-divider>
                    <a class="txt-btn danger" @mousedown.stop @mouseup.stop @click.stop="delRow(record, index)" v-if="!hideDelete">删除</a>
                    <slot name="action" v-bind:item="record"></slot>
                </template>
            </a-table>
        </div>
        <a-modal :maskClosable="false" v-model="visible" :width="modalWidth" :title="`${single.id ? '编辑' : '添加'}${name}`" ok-text="确认" cancel-text="取消" @ok="confirm">
            <a-form-model
                    class="item-form"
                    ref="editForm"
                    :model="single"
                    :rules="rules"
                    :labelCol="modalForm.labelCol"
                    :wrapperCol="modalForm.wrapperCol"
                    :layout="modalForm.layout"
                    :labelAlign="modalForm.labelAlign">
                <slot name="edit" v-bind:form="single"></slot>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    import {clone} from "../common/js/tool";

    export default {
        name: "data-list",
        props: {
            data: Array,
            hideQuery: Boolean, //是否隐藏筛选
            hideAction: Boolean, //是否隐藏操作列
            hideEdit: Boolean, //是否隐藏编辑按钮
            hideDelete: Boolean, //是否隐藏删除按钮
            showRowNum: Boolean, //是否显示行编号
            beforeEdit: Function, //显示编辑条目之前的处理函数
            beforeUpdate: Function, //添加/编辑条目点击确定之前的处理函数
            customDelete: Function, //自定义删除操作
            customUpdate: Function, //自定义添加、更新条目操作
            dealList: Function, //自定义数据处理函数,
            customUrl: Function, //自定义请求接口URL函数
            addBtn: [String, Boolean], //添加条目按钮文案
            url: String,
            query: String,
            rowSelection: Object,
            width: Number, //表格内容区域宽度
            height: Number, //表格内容区域高度 不传自适应
            addRoute: String, //点击添加条目跳转的路由name
            actionWidth: Number, //操作列宽度
            //搜索字段的搜索方式 {字段名：搜索方式} true 模糊搜索 false 精确搜索
            defaultQuery: Object, //查询字段默认值
            searchKeyType: {
                type: Object,
                default() {
                    return {}
                }
            },
            columns: {
                type: Array,
                default() {
                    return []
                }
            },
            name: {
                type: String,
                default: '条目'
            },
            rowKey: {
                type: String,
                default: 'id'
            },
            //modal Form配置
            modalForm: {
                type: Object,
                default() {
                    return {layout: 'vertical', labelAlign: 'left'}
                }
            },
            modalWidth: {
                type: Number,
                default: 400
            },
            rules: {
                type: Object,
                default() {
                    return {};
                }
            }
        },
        data() {
            return {
                loading: false,
                h: 300,
                form: {},
                single: {},
                list: [],
                visible: false,
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                index: null
            }
        },
        computed: {
            scroll() {
                let temp = {y: this.h};
                return this.width ? {...temp, x: this.width} : temp;
            },
            renderColumns() {
                let pre = [], end = [], columns = this.columns || [];
                if(this.showRowNum) {
                    pre = [{title: '序号', dataIndex: 'rowIndex', key: 'index', width: 60}]
                }
                if(!this.hideAction) {
                    let temp = {title: '操作', key: 'action', fixed: 'right', scopedSlots: { customRender: 'action'}};
                    temp.width = this.actionWidth || 120;
                    end = [temp];
                }
                return pre.concat(columns).concat(end);
            },
            api() {
                return this.url + this.query;
            }
        },
        watch: {
            'pagination.total'(val) {
                this.$emit('countChange', val);
            },
            api() {
                this.getList();
            },
            height() {
                this.setHeight();
            },
            defaultQuery() {
                this.setQuery();
            }
        },
        created() {
            this.setQuery();
            this.getList();
        },
        mounted() {
            this.setHeight();
            window.addEventListener("resize", this.setHeight, false);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setHeight, false);
        },
        methods: {
            setQuery() {
                let val = this.defaultQuery;
                if(val) {
                    for(let key in val) {
                        this.$set(this.form, key, val[key]);
                    }
                } else {
                    this.form = {};
                }
            },
            customRow(record, index) {
                let time = 0;
                return {
                    on: { // 事件
                        mousedown: (e) => {
                            time = e.timeStamp;
                        },
                        mouseup: (e) => {
                            let offset = e.timeStamp - time;
                            //区分长按和点击
                            if(offset < 300) {
                                this.$emit('rowClick', {e, item: record, index});
                            }
                        }
                    },
                };
            },
            confirm() {
                this.$refs.editForm.validate(flag => {
                    if(flag) {
                        let form = this.single;
                        if(typeof this.beforeUpdate === 'function') {
                            this.beforeUpdate(form, res => {
                                this.updateItem(res || form);
                            });
                        } else {
                            this.updateItem(form);
                        }
                    }
                })
            },
            updateItem(form) {
                delete form.create_time;
                delete form.update_time;
                if(typeof this.customUpdate === "function") {
                    this.customUpdate(form, this.index, () => {
                        this.getList();
                        this.hideModal();
                    });
                } else {
                    let url = this.url;
                    if(form.id) {
                        url += `/${form.id}`;
                    }
                    this.$axios({
                        url,
                        method: form.id ? 'PATCH' : 'POST',
                        data: form
                    }).then(() => {
                        this.$message.success(form.id ? '更新成功' : '添加成功');
                        this.getList();
                        this.hideModal();
                    });
                }
            },
            setHeight() {
                let height = this.height;
                if(height) {
                    this.h = height;
                } else {
                    let dataTale = this.$refs.dataTable;
                    if(dataTale) {
                        let area = this.$el.querySelector(".data-area");
                        this.h = area.clientHeight - 54 - 64;
                    }
                }
            },
            showModal() {
                this.visible = true;
            },
            hideModal() {
                this.visible = false;
            },
            addItem() {
                //如果传入路由则跳转 否则检测编辑插槽是否有内容 两者都没有抛出事件
                if(this.addRoute) {
                    this.$router.push({name: this.addRoute});
                } else if(this.$scopedSlots.edit) {
                    this.editRow({});
                } else {
                    this.$emit("add");
                }
            },
            editRow(item, index) {
                this.index = index
                let form = clone(item);
                if(typeof this.beforeEdit === 'function') {
                    this.beforeEdit(form, res => {
                        this.showEdit(res || form);
                    });
                } else if(this.$scopedSlots.edit) {
                    this.showEdit(form);
                } else {
                    this.$emit("edit", form);
                }
            },
            showEdit(form) {
                this.single = form;
                this.showModal();
            },
            delRow(item, index) {
                if(typeof this.customDelete === 'function') {
                    this.customDelete(item, index, () => {
                        this.getList();
                    })
                } else {
                    this.$confirm({
                        title: '提示',
                        content: `确定删除此${this.name}吗？`,
                        onOk: () => {
                            this.$axios({
                                url: `${this.url}/${item.id}`,
                                method: 'DELETE'
                            }).then(() => {
                                this.$message.success('删除成功');
                                this.getList();
                            })
                        }
                    });
                }
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
            //对外的处理内部数据的方法 接收一个数据处理函数并刷新数据
            dealData(fn) {
                typeof fn === "function" && fn(this.list, list => {
                    this.list = list;
                })
            },
            getList() {
                let url = this.url;
                if(!url) return;
                this.loading = true;
                let query = this.query, form = this.form;
                let {current, pageSize} = this.pagination;
                url += `?page=${current}&pageSize=${pageSize}`;
                if(this.customUrl && typeof this.customUrl == "function") {
                    url += this.customUrl(url, form);
                } else {
                    let searchKeyType = this.searchKeyType;
                    for(let key in form) {
                        if(form[key]) {
                            //模糊搜索
                            if(searchKeyType[key]) {
                                url += `&filter[${key}][like]=${form[key]}`;
                            } else {
                                url += `&filter[${key}]=${form[key]}`;
                            }
                        }
                    }
                }
                if(query) {
                    url += query;
                }
                this.$axios(url).then(res => {
                    this.loading = false;
                    let list = res.data;
                    if(this.showRowNum) {
                        list.forEach((item, index) => {
                            item.rowIndex = (current - 1) * pageSize + index + 1;
                        });
                    }
                    if(typeof this.dealList === 'function') {
                        this.dealList(list, res => {
                            this.list = res || list;
                        })
                    } else {
                        this.list = list;
                    }
                    if(res.page) {
                        this.$set(this.pagination, 'total', res.page.totalCount);
                    }
                }).catch(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<style scoped lang="less">
    .data-title {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: @padding-md;
        border-bottom: var(--border);
    }
    .title-left {
        align-items: center;
    }
    .data-area {
        flex: 1;
        margin: @padding-md 0;
        overflow: auto;
    }
    .query-form {
        flex: 1;
        text-align: right;
    }
    .data-page {
        text-align: right;
    }
</style>
