const dev = true;
const localHost = 'http://museum.qihe.local';
const proHost = 'https://museum.qihe.live';
const devHost = dev ? localHost : proHost;
const host = process.env.NODE_ENV === 'production' ? proHost : devHost

export default {
  host,
  token: null
}
