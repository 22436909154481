<template>
    <div class="select-entity">
        <a-button class="select-entity-btn" @click="showModal" v-show="multiple || entityList.length == 0" ><a-icon type="select"/>{{btnText}}</a-button>
        <ul class="entity-list" v-show="entityList && entityList.length > 0">
            <li class="entity-item" v-for="(item, i) in entityList" :key="item.id">
                <div class="entity-name">{{item.name}}</div>
                <a-icon type="delete" style="cursor: pointer" @click="removeSelect(i)"/>
            </li>
        </ul>
        <a-modal
            :title="btnText"
            :maskClosable="false"
            :closable="false"
            :destroyOnClose="true"
            :width="600"
            @ok="confirmSelect"
            v-model="visible">
            <data-list
                hide-action
                :height="500"
                :url="url"
                :search-key-type="{name: true}"
                :row-selection="{type: multiple ? 'checkbox' : 'radio', selectedRowKeys: selectKeys}"
                :columns="columns"
                @rowClick="handleRowClick"
                v-if="url">
                <template v-slot:query="{form}">
                    <a-form-model-item label="">
                        <a-input v-model="form.name" placeholder="输入姓名/名称查询"/>
                    </a-form-model-item>
                </template>
            </data-list>
        </a-modal>
    </div>
</template>

<script>
    import {clone, isArray} from "../common/js/tool";

    export default {
        name: "select-entity",
        model: {
            event: 'change'
        },
        props: {
            btnText: {
                type: String,
                default: '点击选择'
            },
            type: String,
            value: [Array, Object],
            multiple: Boolean
        },
        data() {
            return {
                visible: false,
                entity: null,
                selectKeys: [],
                selectItems: [],
                entityList: []
            }
        },
        created() {
            this.setEntityList()
        },
        computed: {
            url() {
                let type = this.type;
                let res = null
                switch (type) {
                    case 'teacher':
                        res = '/teacher';
                        break;
                    case 'activity':
                        res = '/activity';
                        break;
                    case 'audio':
                        res = '/audio';
                        break;
                    case 'museum':
                        res = '/museum';
                        break;
                    case 'album':
                        res = '/album';
                        break;
                    case 'exhibition':
                        res = '/exhibition';
                        break;
                }
                return res;
            },
            columns() {
                let type = this.type;
                let res = null
                switch (type) {
                    case 'teacher':
                        res = [
                            {title: '姓名', dataIndex: 'name'},
                            {
                                title: '头像',
                                dataIndex: 'img',
                                customRender: text => {
                                    return <img class="avatar mini" src={text} />
                                }
                            },
                        ];
                        break;
                    case 'activity':
                        res = [
                            {title: '活动名称', dataIndex: 'name'},
                        ];
                        break;
                    case 'audio':
                        res = [
                            {title: '音频名称', dataIndex: 'name'},
                            {
                                title: '音频封面',
                                dataIndex: 'img',
                                customRender: text => {
                                    return <img class="avatar mini" src={text} />
                                }
                            },
                        ];
                        break;
                    case 'museum':
                        res = [
                            {title: '博物馆名称', dataIndex: 'name'},
                            {
                                title: '博物馆封面',
                                dataIndex: 'img',
                                customRender: text => {
                                    return <img class="avatar mini" src={text} />
                                }
                            },
                        ];
                        break;
                    case 'album':
                        res = [
                            {title: '专辑名称', dataIndex: 'name'},
                            {
                                title: '专辑封面',
                                dataIndex: 'img',
                                customRender: text => {
                                    return <img class="avatar mini" src={text} />
                                }
                            },
                        ];
                        break;
                    case 'exhibition':
                        res = [
                            {title: '展览名称', dataIndex: 'name'},
                            {
                                title: '展览图片',
                                dataIndex: 'img',
                                customRender: text => {
                                    return <img class="avatar mini" src={text} />
                                }
                            },
                        ];
                        break;
                }
                return res;
            }
        },
        watch: {
            value() {
                this.setEntityList();
            }
        },
        methods: {
            setEntityList() {
                let value = this.value;
                if(value) {
                    if(isArray(value)) {
                        this.entityList = this.value;
                    } else {
                        this.entityList = [this.value]
                    }
                } else {
                    this.entityList = [];
                }
            },
            removeSelect(index) {
                this.entityList.splice(index, 1);
                this.dispatchInput();
            },
            showModal() {
                let list = this.entityList;
                let keys = list.map(item => item.id);
                this.selectKeys = keys;
                this.selectItems = clone(list)
                this.visible = true;
            },
            handleRowClick({item}) {
                let keys = this.selectKeys;
                if(keys.length > 0 && keys[0] == item.id) {
                    this.selectKeys = [];
                    this.selectItems = [];
                } else {
                    this.selectKeys = [item.id];
                    this.selectItems = [item];
                }
            },
            hideModal() {
                this.selectKeys = [];
                this.selectItems = [];
                this.visible = false;
            },
            dispatchInput(list) {
                list = list || this.entityList;
                this.$emit('change', list.length > 1 ? list : list[0]);
            },
            confirmSelect() {
                if(this.selectItems.length > 0) {
                    this.entityList = this.selectItems;
                    this.dispatchInput(this.entityList);
                }
                this.hideModal();
            }
        }
    }
</script>

<style scoped lang="less">
    .select-entity-btn {
        margin: 4px 0 @padding-sm;
    }
    .entity-list {
        display: flex;
    }
    .entity-item {
        display: flex;
        align-items: center;
        padding: @padding-xs;
        min-width: 240px;
        border: var(--border);
        border-radius: @border-radius-base;
        &:not(:first-of-type) {
            margin-top: @padding-xs;
        }
    }
    .entity-name {
        flex: 1;
        margin-right: @padding-sm;
        line-height: 1.5;
    }
</style>
