import "./common/js/init"
import Vue from 'vue'
import Local from './Local.vue'
import router from './router'
import store from './store'
import common from './components/index'
import axios from './common/js/req'
import './plugins/ant-design-vue.js'

Vue.use(common);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: h => h(Local)
}).$mount('#app')
