import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import info from './modules/info'
import status from './modules/status'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    login,
    info,
    status
  },
  strict: debug
})
