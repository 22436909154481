<template>
    <div class="tiny-editor">
        <editor
            :value="value"
            v-on="$listeners"
            api-key="h6uoczqcxygr0dh98i6ydyimyz3z8vxu889egwawv9yt2jmz"
            :init="init"
        />
    </div>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue'
    import {directUploadTencentCloud} from "../common/js/tencent_cloud";
    export default {
        name: "tiny-editor",
        components: {
            Editor
        },
        props: {
            value: String
        },
        data() {
            return {
                init: {
                    height: 500,
                    toolbar_mode: 'wrap',
                    menubar: 'file edit view insert format tools table tc help',
                    language: 'zh_CN',
                    default_font_size: '14px',
                    images_upload_handler: this.uploadHandler,
                    file_picker_callback: this.filePickerCallback,
                    video_template_callback: this.videoTemplateCallback,
                    file_picker_types: 'media',
                    lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 1.6 1.75 1.8 2 3 4',
                    fontsize_formats: "12px 14px 16px 18px 20px 24px 28px 32px 36px 40px 48px 54px",
                    font_formats:"微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",
                    plugins: [
                        'advlist autolink lists link image  imagetools media charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | bold italic underline strikethrough lineheight | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment'
                }
            }
        },
        methods: {
            uploadHandler(blobInfo, success, failure, progress) {
                let file = new File([blobInfo.blob()], blobInfo.filename());
                directUploadTencentCloud(file,p => {
                    progress(Math.round(p.percent * 100));
                }).then(data=>{
                    let path = "https://" + data.Location;
                    success(path)
                }).catch(failure)
            },
            filePickerCallback(callback, value, meta) {
                if (meta.filetype == 'media'){
                    //创建一个隐藏的type=file的文件选择input
                    let input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute("accept", "video/*");
                    input.onchange = function(){
                        let file = this.files[0];
                        directUploadTencentCloud (file).then(data => {
                            let path = "https://" + data.Location;
                            callback(path);
                        }).catch(err => {
                            console.log(err)
                        });
                    };
                    //触发点击
                    input.click();
                }
            },
            videoTemplateCallback(data) {
                return `<video style="width:100%;height:56.25%;"${data.poster ? ' poster="' + data.poster + '"' : ''} controls="controls"><source src="${data.source }"${data.sourcemime ? ' type="' + data.sourcemime + '"' : ''} /></video>`;
            }
        }
    }
</script>

<style scoped>

</style>
