import axios from 'axios';
import conf from "./config";
import { message} from 'ant-design-vue';
import {getToken} from "./storage";

const openApi = ['/official/admin-auth'];

let hide = null, timer = null;
const hideLoading = function() {
  if(timer) {
    clearTimeout(timer);
    timer = null;
  } else {
    typeof hide === 'function' && hide();
    hide = null;
  }
}
axios.interceptors.request.use(config => {
  let token = getToken();
  if(openApi.indexOf(config.url) == -1 && !token) {
    location.replace('/login');
    return null;
  }
  if (!/^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/.test(config.url)) {
    config.url = conf.host + config.url;
  }
  let headers = {'Content-Type': 'application/json'};
  token && (headers["Authorization"] = 'Bearer ' + token.token);
  config.headers = Object.assign(headers, config.headers);
  if(config.loadingText) {
    timer = setTimeout(() => {
      let text = config.loadingText;
      hide = message.loading(text, 0);
      timer = null;
    }, 300);
  }
  // config.withCredentials = true;
  return config;
}, err => {
  message.warning('请求超时');
  return Promise.reject(err);
});
axios.interceptors.response.use(res => {
  hideLoading();
  let status = res.status;
  if (status && /20[0-9]/.test(status + '')) {
    return res.data;
  }  else {
    return Promise.reject(res);
  }
}, err => {
  hideLoading();
  let res = err.response;
  if(res) {
    let status = res.status;
    if(status == 401) {
      location.replace('/login');
    } else if(status == 403) {
      message.warning('您没有权限');
    } else if(res.data && res.data.message) {
      message.warning(res.data.message);
    } else if(/4[0-9][0-9]/.test(status + '')) {
      message.warning('请求不到资源');
    } else if(/4[0-9][0-9]/.test(status + '')) {
      message.warning('服务器开小差了');
    } else {
      message.warning('系统繁忙');
    }
  } else {
    message.warning('系统繁忙');
  }
  return Promise.reject(err);
});

export default axios;
